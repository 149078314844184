import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ComponentPropsProvider, EntityTitleHeader, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';

const cls = classNames('page-layout-knowledge-hub');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutKnowledgeHub: PageLayoutComponent = 
(props) => {
  const { className, slices, children } = props;
  const entity = useEntity(props.entity);

  return (
    <ComponentPropsProvider value={{ 
      PostCard: {
        descriptionMaxLines: 10,
      }
     }}>
    <My.Container className={cls.name(className)}>
    <EntityTitleHeader colors={{ background: '#202124', text: 'light' }} entity={entity} />
    {slices}
    </My.Container>
    </ComponentPropsProvider>
  )
};