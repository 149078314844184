import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Slice, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, useEntity } from '@shapeable/ui';
import { BlockThumbnail } from './block-thumbnail';
import { classNames } from '@shapeable/utils';
const cls = classNames('slice-block-thumbnail-grid');

// -------- Types -------->

export type SliceBlockThumbnailGridProps = Classable & HasChildren & { 
  entity?: Slice;
};

export const SliceBlockThumbnailGridDefaultProps: Omit<SliceBlockThumbnailGridProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: #000000;
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const BlockStyles = breakpoints({
  base: css`
    height: 100%;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Grid: styled(Grid)`${GridStyles}`,
    Block: styled(BlockThumbnail)`${BlockStyles}`,
};

export const SliceBlockThumbnailGrid: Shapeable.FC<SliceBlockThumbnailGridProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { blocks = [] } = entity;

  if (!blocks.length) {
    return null;
  }

  return (
    <My.Container className={cls.name(className)}>
      <My.Grid 
        spacing={0}
        columns={3}
        items={blocks.map(block => <My.Block key={block.id} entity={block} />)}
      />
    </My.Container>
  )
};

SliceBlockThumbnailGrid.defaultProps = SliceBlockThumbnailGridDefaultProps;
SliceBlockThumbnailGrid.cls = cls;