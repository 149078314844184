import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Slice, Shapeable } from '@shapeable/types';
import { AspectRatio, breakpoints, theme } from '@shapeable/theme';
import { classNames, coalesce } from '@shapeable/utils';
import { ContentNode, useEntity, useLang, EntityLinkButton, LinkButton } from '@shapeable/ui';
import { SliceBackgroundImageOverlay } from './slice-background-image-overlay';

const cls = classNames('slice-section-content-block');


// -------- Types -------->

export type SliceSectionContentBlockProps = Classable & HasChildren & { 
  entity?: Slice;
  preBody?: React.ReactNode;
  preTitle?: React.ReactNode;
  isReversed?: boolean;
  aspectRatio?: AspectRatio;
  imageBackgroundColor?: string;
  maxLayoutWidth?: string;
  backgroundColor?: string;
};

export const SliceSectionContentBlockDefaultProps: Omit<SliceSectionContentBlockProps, 'entity'> = {
  maxLayoutWidth: '416px',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type LayoutProps = {
  maxLayoutWidth: string;
}

type BodyProps = {
  maxLayoutWidth: string;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    .shp--slice-section-color-block__title + 
    .shp--slice-section-color-block__buttons {
      margin-top: ${theme.UNIT(8)};
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${theme.FONT('heading')}
    }

    h1, h2, h3, h4, h5, h6, strong, b, em, ul li::before {
      color: inherit; /* override bullet colors */
    }
  `,
});

const ContainerBodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)};
    display: flex;
    align-items: center;
  `,
  tablet: css`
    padding: ${theme.UNIT(12)} ${theme.UNIT(8)};
  `,
});

const CommonContentStyles = breakpoints({
  base: css`
    font-size: inherit;
    font-weight: 300;
    line-height: 1.5em;
    color: inherit;
    margin-top: ${theme.UNIT(6)};

    p:last-child {
      margin-bottom: 0;
    }
  `,
});

const IntroStyles = breakpoints({
  base: css`
    ${CommonContentStyles};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    ${CommonContentStyles};
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    margin: 0 auto;
    width: 100%;
    justify-content: center;

  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
  `,
  tablet: css`
    ${({ maxLayoutWidth }: LayoutProps ) => maxLayoutWidth && css`
      max-width: ${maxLayoutWidth}; 
    `}
  `,
});

const TitleSizeStyles = breakpoints({
  base: css`
    color: inherit;
    font-size: 1.75em; 
    line-height: 1.15em;
    font-weight: 500;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
  `,
  tablet: css`
    font-size: 2em; 
  `,
  desktop: css`
    font-size: 2.25em; 
  `,
});


const TitleStyles = breakpoints({
  base: css`
    font-weight: 500;
    ${TitleSizeStyles};
  `,
  tablet: css`
  `,
  desktop: css`
  `,
});


const ButtonsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const ButtonInnerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
  `,
});



const ButtonStyles = breakpoints({
  base: css`
    width: auto;
    margin-top: ${theme.UNIT(4)};
  `,
});

const LinkStyles = breakpoints({
  base: css`
    ${ButtonStyles};
  `,
});


const PageLinkStyles = breakpoints({
  base: css`
    ${ButtonStyles};
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    ${TitleSizeStyles};
    font-weight: 300;
  `,
});



// -------- Components -------->

const My = {
  Container: styled(SliceBackgroundImageOverlay)<ContainerProps>`${ContainerStyles}`,
    Layout: styled.div.attrs(cls.attr('layout'))<LayoutProps>`${LayoutStyles}`,

      Body: styled.div.attrs(cls.attr('body'))<BodyProps>`${BodyStyles}`,
        Title: styled.h2.attrs(cls.attr('title'))`${TitleStyles}`,
        Subtitle: styled.h3.attrs(cls.attr('subtitle'))`${SubtitleStyles}`,
        Intro: styled(ContentNode)`${IntroStyles}`,
        Content: styled(ContentNode)`${ContentStyles}`,

      Buttons: styled.div.attrs(cls.attr('buttons'))`${ButtonsStyles}`,
      PageLink: styled(EntityLinkButton)`${PageLinkStyles}`,
      Link: styled(LinkButton)`${LinkStyles}`,
};  

export const SliceSectionContentBlock: Shapeable.FC<SliceSectionContentBlockProps> = (props) => {
  const { className, children, isReversed, aspectRatio, preBody, preTitle, backgroundColor, imageBackgroundColor, maxLayoutWidth } = props;
  const entity = useEntity(props.entity);
  
  const { title, subtitle, intro, content, links = [], pages = [] } = entity;

  const t = useLang();

  // console.log(props);
  
  return (
    <My.Container imageBackgroundColor={imageBackgroundColor} backgroundColor={backgroundColor} isReversed={isReversed} entity={entity} bodyStyles={ContainerBodyStyles} aspectRatio={aspectRatio} className={cls.name(className)}>
      <My.Layout maxLayoutWidth={maxLayoutWidth}>
        {preBody}
        <My.Body maxLayoutWidth={maxLayoutWidth}>
          {
            title && 
            <My.Title>{preTitle}{title}</My.Title>
          }
          {
            subtitle && 
            <My.Subtitle>{subtitle}</My.Subtitle>
          }
          <My.Intro entity={intro} />
          <My.Content entity={content} />
          <My.Buttons>
          {
            links.map(link => <My.Link buttonStyles={ButtonInnerStyles} isDownload={link.isDownload} key={link.id} href={coalesce(link, ['file.file.url', 'page.path', 'url'])}>{t(coalesce(link, ['label', 'name']))}</My.Link>)
          }
          </My.Buttons>
        </My.Body>
      </My.Layout>
    </My.Container>
  )
};

SliceSectionContentBlock.defaultProps = SliceSectionContentBlockDefaultProps;
SliceSectionContentBlock.cls = cls;