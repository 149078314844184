import React from 'react';
import styled, { css } from 'styled-components';
import { ImageEntityBackground, useEntity, ImageEntity } from '@shapeable/ui';
import { Classable, Slice, HasChildren } from '@shapeable/types';
import { AspectRatio, breakpoints, CSS, EXTRA_CSS, HasCss, theme } from '@shapeable/theme';
import { first, trim } from 'lodash';
import { DiagonalMobileSlice } from '../elements/diagonal-mobile-slice';
import { classNames } from '@shapeable/utils';
const cls = classNames('slice-background-image-overlay');

// -------- Types -------->

export type SliceBackgroundImageOverlayProps = Classable & HasChildren & { 
  entity: Slice;
  isReversed?: boolean;
  aspectRatio?: AspectRatio;
  bodyStyles?: CSS;
  backgroundColor?: string;
  imageBackgroundColor?: string;
  maxLayoutWidth?: string;
};

export const SliceBackgroundImageOverlayDefaultProps: Omit<SliceBackgroundImageOverlayProps, 'entity'> = {
  aspectRatio: {
    base: 4 / 3,
    landscape: 21 / 9,
    tablet: 21 / 9,
    desktop: 2.5,
  },
  imageBackgroundColor: '#00000000',
  maxLayoutWidth: '500px',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type LayoutProps = {
  isReversed: boolean;
  maxLayoutWidth: string;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background-blend-mode: hard-light, normal;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
  `,
  tablet: css`
    flex-direction: row;
    
    ${({ isReversed }: LayoutProps) => isReversed && css`
      flex-direction: row-reverse;
    `}
  `
});

const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    margin-top: -1px;
    background-color: #3E839B;
  `,
  tablet: css`
    width: 50%;
    background-color: #00000000;
  `,
  extra: theme.EXTRA_CSS
});

const ImageStyles = breakpoints({
  base: css`
    object-fit: fill;
    width: 100%;
  `,
  tablet: css`
    width: 50%;
  `
});

const DiagonalSliceContainerStyles = breakpoints({
  base: css`
  
  `,
  tablet: css`
    display: none;
  `,
});



const DiagonalSliceStyles = breakpoints({
  base: css`
    
  `,
  tablet: css`
    display: none;
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ImageEntityBackground)<ContainerProps>`${ContainerStyles}`,
    Layout: styled.div.attrs(cls.attr('layout'))<LayoutProps>`${LayoutStyles}`,
    DiagonalSliceContainer: styled.div.attrs(cls.attr('test'))`${DiagonalSliceContainerStyles}`,
      DiagonalSlice: styled(DiagonalMobileSlice)`${DiagonalSliceStyles}`,
      Body: styled.div.attrs(cls.attr('body'))<HasCss>`${BodyStyles}`,
      Image: styled(ImageEntity)`${ImageStyles}`,
};

export const SliceBackgroundImageOverlay: React.FC<SliceBackgroundImageOverlayProps> = (props) => {
  const { className, children, isReversed, bodyStyles, aspectRatio, imageBackgroundColor, maxLayoutWidth } = props;
  const entity = useEntity(props.entity);
  
  const { intro, backgroundImages, images } = entity;

  const image = first(images);


  return (
    <My.Container 
      aspectRatio={aspectRatio} 
      items={backgroundImages} 
      className={cls.name(className)}
    >
    <My.Layout maxLayoutWidth={maxLayoutWidth} isReversed={isReversed}>

      <My.Body extraStyles={bodyStyles}>
      {children}
      </My.Body>
      <My.DiagonalSliceContainer>
       <My.DiagonalSlice />
      </My.DiagonalSliceContainer>
      {
        image &&
        <My.Image entity={image} />
      }
    </My.Layout>
    </My.Container>
  )
};

SliceBackgroundImageOverlay.defaultProps = SliceBackgroundImageOverlayDefaultProps;