import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { BannerTitles } from '../entities/banner-titles';
import { PageLayoutStandard } from '@shapeable/ui';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`

    /* override title styles via BEM here to affect spacing */ 
    .shp--banner-titles {
      color: ${theme.COLOR('strong')};
      width: 180px;
    }

  `,
  tablet: css`
    .shp--banner-titles {
      width: 100%;
    }
  `,
  desktop: css`
    .shp--banner-titles {
        
    }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      bannerTitles={<BannerTitles entity={entity} />}
      headerProps={{ 
        showOverlayGradient: true, 
        variant: 'overlay', 
        backgroundColor: '#000000',
        fadeColor: '#0000000', 
       }}
      className={className}
    />
  )
};
