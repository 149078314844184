import React from 'react';
import styled, { css } from 'styled-components';
const cls = classNames('block-thumbnail');
import { Block, Classable, HasChildren, Shapeable } from '@shapeable/types';
import { AspectRatio, breakpoints, theme } from '@shapeable/theme';
import { 
  ImageEntityBackground, useLang, useLink, EntityLinkButton,
  LinkButton, ContentEntityRichSubtitle, ContentEntityRichTitle
} from '@shapeable/ui';

import { classNames, openLink } from '@shapeable/utils';
import { coalesce } from '@shapeable/utils';

import { get } from 'lodash';

// -------- Types -------->

export type BlockThumbnailProps = Classable & HasChildren & {
  entity: Block;
  aspectRatio?: AspectRatio;
}

export const BlockThumbnailDefaultProps: Omit<BlockThumbnailProps, 'entity'> = {
  aspectRatio: {
    base: 16 / 9,
    desktop: 4 / 3,
  }
};

// -------- Child Component Props -------->

type ContainerProps = {
  hasLink: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${({ hasLink }: ContainerProps ) => hasLink && css`
      cursor: pointer;
    `}
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    padding: ${theme.UNIT(6)};
    height: 100%;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    text-transform: uppercase;
    color: ${theme.COLOR('light')};
    font-weight: 300;
    margin: 0;
    font-size: ${theme.FONT_SIZE(18)};
    padding-bottom: ${theme.UNIT(2)};

    strong {
      color: inherit;
    }
  `,
  desktop: css`
    ${theme.H_DOT_BG_END('light')};
  `
});

const SubtitleContainerStyles = breakpoints({
  base: css`
    min-height: 48px;
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('serif')};
    color: ${theme.COLOR('light')};
    font-weight: 500;
  `,
  
});

const ButtonStyles = breakpoints({
  base: css`
    background-color: rgba(0,0,0,0.3);

    &:hover {
      background-color: rgba(0,0,0,0.5);
    }
  `,
});


const PageLinkButtonStyles = breakpoints({
  base: css`
    ${ButtonStyles};
  `,
});

const LinkButtonStyles = breakpoints({
  base: css`
    ${ButtonStyles};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ImageEntityBackground)<ContainerProps>`${ContainerStyles}`,
    Body: styled.div.attrs(cls.attr('body'))`${BodyStyles}`,
      Title: styled(ContentEntityRichTitle).attrs(cls.attr('title'))`${TitleStyles}`,
      SubtitleContainer: styled.div.attrs(cls.attr('subtitle-container'))`${SubtitleContainerStyles}`,
        Subtitle: styled((ContentEntityRichSubtitle)).attrs(cls.attr('subtitle'))`${SubtitleStyles}`,
      PageLinkButton: styled(EntityLinkButton)`${PageLinkButtonStyles}`,
      LinkButton: styled(LinkButton)`${LinkButtonStyles}`,
};

export const BlockThumbnail: Shapeable.FC<BlockThumbnailProps> = (props) => {
  const { className, entity, aspectRatio } = props;
  const { link, page, title, subtitle, titleRich, subtitleRich } = entity;

  const { navigate } = useLink();

  
  const hasTitle = title || get(titleRich, 'text');
  const hasSubtitle = subtitle || get(subtitleRich, 'text');
  const image = coalesce(entity, ['image', 'page.banner']);
  const buttonColor = "light";
  const buttonHoverColor = "light";
  
  const hasLink = !!(link && link.url);
  const hasPage = !!page;

  const onClickProps = (hasLink) ? 
    { 
      onClick: () => { openLink(link.url, navigate); } 
    } : 
    (!!page ? { onClick:() => { navigate(page.path); } } : { });
  
  const t = useLang();

  return (
    <My.Container {...onClickProps} hasLink={hasPage || hasLink} aspectRatio={aspectRatio} overlayColor="rgba(0,0,0,0.30)" entity={image} className={cls.name(className)}>
    <My.Body>
      {
        hasTitle && 
        <My.Title entity={entity}/>
      }
      {
        hasSubtitle && 
        <My.SubtitleContainer>
          <My.Subtitle entity={entity} />
        </My.SubtitleContainer>
      }
      {
        (link && link.url) ?
        <My.LinkButton isDownload={link.isDownload} href={link.url}>{t(coalesce(link, ['label', 'name']))}</My.LinkButton> : 
        (
          page &&
          <My.PageLinkButton entity={page}>{t('View Case Study')}</My.PageLinkButton>
        )
      }
    </My.Body>
    </My.Container>
  )
};

BlockThumbnail.defaultProps = BlockThumbnailDefaultProps;
BlockThumbnail.cls = cls;