import { 
  SliceContentImage,
  SliceOrganisations,
  SliceBlockImage as CoreSliceBlockImage,
  createSliceLayout,
} from '@shapeable/ui';
import { SliceBannerTout } from '../entities/slice-banner-tout';
import { SliceBlockThumbnailGrid } from '../entities/slice-block-thumbnail-grid';
import { SliceSectionContentBlock } from '../entities/slice-section-content-block';


// Augment standard site layouts here by exporting additional or replacement layouts.
// (simply use the same key to replace any existing slice layouts).

export const SliceLayoutContentImageStretch = createSliceLayout(
  SliceSectionContentBlock, {
    verticalPadding: 'none',
    boundary: 'none',
    fadeColor: '#00000000',
    props: {
      
    },
  }
);

export const SliceLayoutBlockThumbnailGrid = createSliceLayout(
  SliceBlockThumbnailGrid, {
    verticalPadding: 'none',
    boundary: 'none',
  }
);

export const SliceBlockImage = createSliceLayout(
  CoreSliceBlockImage, {
    verticalPadding: 'none',
    boundary: 'none',
  }
);


export const SliceLayoutBannerTout = createSliceLayout(
    SliceBannerTout, {
      verticalPadding: 'medium',
      fadeColor: '#00000000',
    }
)

export const SliceLayoutContentImage = createSliceLayout(
  SliceContentImage, {
    verticalPadding: 'none',
    props: {
      imageWidth: '100',
    }
  }
);

export const SliceLayoutOrganisations = createSliceLayout(
  SliceOrganisations, {
    verticalPadding: 'content',
    props: {
      hasHeader: false,
    }
  }
);

export const SliceLayoutKeyMessage = createSliceLayout(
  SliceOrganisations, {
    verticalPadding: 'content',
    props: {
      hasHeader: false,
    }
  }
);