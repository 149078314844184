import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ImageAsset, Slice } from '@shapeable/types';
import { AspectRatio, breakpoints, theme } from '@shapeable/theme';
import { ImageEntityBackground, ContentEntityContent, useLink, 
ContentEntityRichSubtitle, ContentEntityRichTitle, LinkButton, useLang, LinkButtonProps, useColorInfo, 
SiteHeaderLayoutProps} from '@shapeable/ui';
import { first, get, map } from 'lodash';
import { YELLOW_DARK } from '../../theme';
import { classNames, truncateWords, coalesce } from '@shapeable/utils';

const cls = classNames('slice-banner-tout');


// -------- Types -------->

// -------- Props -------->

export type SliceBannerToutProps = Classable & HasChildren & Omit<SiteHeaderLayoutProps, 'entity'> & { 
  entity?: Slice;
  aspectRatio?: AspectRatio;
  color?: string;
  overlayColor?: string;
  backgroundColor?: string;
  linkButtonProps?: Partial<LinkButtonProps>;
};

export const SliceBannerToutDefaultProps: Omit<SliceBannerToutProps, 'entity'> = {
  aspectRatio: {
    base: 4 / 3,
    landscape: 21 / 9,
    tablet: 21 / 9,
    desktop: 2.5,
  },
  showOverlayGradient: true,

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${theme.COLOR('strong')};
  `,
});


const BannerStyles = breakpoints({
  base: css`
    .shp--image-entity-background,
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;
    

    a {
      color: ${theme.COLOR('strong')};
      text-decoration: none;
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});


const TitleStyles = breakpoints({
  base: css`
    font-size: 2em;
    line-height: 1em;
    font-family: ${theme.FONT('sans')};
    font-weight: 400;
    color: ${theme.COLOR('inherit')};
    line-height: 0.9em;

    strong, b {
      font-weight: 500;
      color: inherit;
    }

    p {
      margin-bottom: 1em;
    }

    margin-bottom: ${theme.UNIT(20)};
  `,
  tablet: css`
    font-size: 3em;
  `,
  desktop: css`
    font-size: 5em;
  `,

});


const SubtitleStyles = breakpoints({
  base: css`
    font-size: 1em;
    color: ${theme.COLOR('inherit')};
    margin-bottom: ${theme.UNIT(6)};
  `,
  tablet: css`
    font-size: 1em;
  `,
  desktop: css`
    font-size: 1.3em;
  `,
});

const ContentStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin-bottom: ${theme.UNIT(9)};
    font-size: ${theme.FONT_SIZE(10)};
    max-width: 60%;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(12)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(14)};
  `
});

const ButtonsStyles = breakpoints({
  base: css`
    max-width: 300px;
    margin-top: ${theme.UNIT(4)};
  `,
});

const LinkStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.UNIT(4)};
    cursor: pointer;
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Banner: styled(ImageEntityBackground)<ContainerProps>`${BannerStyles}`,
      Body: styled.div`${BodyStyles}`,
        Header: styled.div`${HeaderStyles}`,
          Title: styled(ContentEntityRichTitle).attrs(cls.attr('title'))`${TitleStyles}`,
          Subtitle: styled(ContentEntityRichSubtitle).attrs(cls.attr('subtitle'))`${SubtitleStyles}`,
        Content: styled(ContentEntityContent).attrs(cls.attr('content'))`${ContentStyles}`,
        Buttons: styled.div.attrs(cls.attr('buttons'))`${ButtonsStyles}`,
          Link:  styled(LinkButton)`${LinkStyles}`,

};

export const SliceBannerTout: Shapeable.FC<SliceBannerToutProps> = (props) => {
  const { 
    className, children, aspectRatio, entity,
    overlayColor, linkButtonProps } = props;

    const { Link } = useLink();
    
    const t = useLang();

    const { content, links = [], title, subtitle, subtitleRich, titleRich, icons } = entity;

    const hasTitle = title || get(titleRich, 'text');
    const hasSubTitle = subtitle || get(subtitleRich, 'text');
    const hasTitles = hasTitle || hasSubTitle;
    const hasContent = content && !!content.text;
    const hasLink = false;

    const path = get(first(links), 'page.path');

    const truncatedText = truncateWords(content.text, { max: 30 });

    const entityContentText = {
      ...entity,
      content: {
        text: truncatedText,
      }
    }

    const { color } = useColorInfo();

  return (
    <My.Container className={cls.name(className)}>
      <My.Banner entity={entity as ImageAsset} aspectRatio={aspectRatio} overlayColor={overlayColor}>
        <My.Body>
          <My.Header>
          {
            hasTitles && 
            <>
              <My.Title entity={entity} />
              <My.Subtitle entity={entity} />
            </>
          }
          </My.Header>
          
        {
          hasContent && <My.Content entity={entityContentText} />
        }
        {
         <My.Buttons>
          {
            links.map(link => <My.Link key={link.id} href={coalesce(link, ['page.path', 'file.file.url', 'url'])} isDownload={link.isDownload}>{t(coalesce(link, ['label', 'name']))}</My.Link>)
          }
         </My.Buttons>
        
        }
        </My.Body>
      </My.Banner>
    </My.Container>
  )
};

SliceBannerTout.defaultProps = SliceBannerToutDefaultProps;
SliceBannerTout.cls = cls;